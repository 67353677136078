import React from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import businessPeopleImage from "../images/business-people.jpg"
import networkingImage from "../images/networking.jpg"
import emailImage from "../images/email.jpg"

import gifImage from "../images/gif.svg"
import untangledImage from "../images/untangle.png"

import { Container, Jumbotron, Row, Col, Button, Image } from "react-bootstrap"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Jumbotron
      fluid
      style={{
        backgroundImage: `url(${businessPeopleImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Container style={{ textAlign: "right" }}>
        <h1
          style={{
            textTransform: "uppercase",
            color: "white",
            fontWeight: 700,
          }}
        >
          Decades of Experience
        </h1>
        <h4
          style={{
            textTransform: "uppercase",
            color: "white",
            fontWeight: 500,
          }}
        >
          Delivering the services you need
        </h4>
        <Button onClick={() => navigate("/contact")}>Contact Us</Button>
      </Container>
    </Jumbotron>

    <Container fluid style={{ margin: "4rem 0 " }}>
      <Row>
        <Col xs={12} md={6}>
          <Image style={{ maxHeight: 420 }} rounded src={networkingImage} />
        </Col>
        <Col
          xs={12}
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
            textAlign: "right",
          }}
        >
          <h1
            style={{
              textTransform: "uppercase",
              fontWeight: 700,
            }}
          >
            Network Solutions
          </h1>
          <h4
            style={{
              textTransform: "uppercase",
              fontWeight: 500,
              marginBottom: "1rem",
            }}
          >
            Resources to solve your networking needs, from hardware to software
            to servers. Up to date security solutions to protect data and
            devices.
          </h4>
          <Button onClick={() => navigate("/networking")}>Learn More</Button>
        </Col>
      </Row>
    </Container>

    <Container fluid style={{ margin: "4rem 0 " }}>
      <Row>
        <Col
          xs={12}
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingBottom: "1rem",
          }}
        >
          <h1
            style={{
              textTransform: "uppercase",
              fontWeight: 700,
            }}
          >
            Email Solutions
          </h1>
          <h4
            style={{
              textTransform: "uppercase",
              fontWeight: 500,
              marginBottom: "1rem",
            }}
          >
            Web-based email solutions as well as software to solve your
            server-based email.
          </h4>
          <Button onClick={() => navigate("/email")}>Learn More</Button>
        </Col>
        <Col xs={12} md={6}>
          <Image style={{ maxHeight: 420 }} rounded src={emailImage} />
        </Col>
      </Row>
    </Container>
    <div style={{ backgroundColor: "#aaa", padding: "2rem 0" }}>
      <h1
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginBottom: "2rem",
        }}
      >
        Our Partners
      </h1>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        <Image
          onClick={() => window.open("https://www.gfi.com/")}
          src={gifImage}
          rounded
          style={{ height: 75, cursor: "pointer" }}
        ></Image>
        <Image
          onClick={() => window.open("https://www.untangle.com/")}
          src={untangledImage}
          rounded
          style={{ maxHeight: 75, cursor: "pointer" }}
        ></Image>
      </div>
    </div>
  </Layout>
)

export default IndexPage
